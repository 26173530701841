import React, { useContext } from "react"
import { injectIntl, Link } from "gatsby-plugin-intl"
import {
  FavStateContext,
  FavDispatchContext,
} from "../../context/favoriteContext"
import {
  Typography,
  Card,
  CardContent,
  Button,
  CardHeader,
  IconButton,
} from "@material-ui/core"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import clsx from "clsx"
import TextTruncate from "react-text-truncate"
import Hyphenated from "react-hyphen"
import de from "hyphenated-de"

const useStyles = makeStyles(theme => ({
  card: {
    padding: "28px",
    border: "2px solid #0028A5",
    borderRadius: "5px",
  },
  cardHeader: {
    padding: 0,
    paddingBottom: "20px",
  },
  titleLink: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  cardText: { minHeight: 100 },
  cardAction: {
    padding: "20px 0",
  },
  buttonMain: {
    marginRight: 5,
  },
  cardChips: {},
  chip: {
    backgroundColor: "transparent",
    fontStyle: "italic",
  },
}))

const ToolCard = props => {
  const theme = useTheme()
  const classes = useStyles()
  const { intl, data } = props
  const dispatch = useContext(FavDispatchContext)
  const state = useContext(FavStateContext)

  const addToFav = () => {
    dispatch({ type: "addFav", payload: data.fields.slug })
  }

  const removeFromFav = () => {
    dispatch({ type: "removeFav", payload: data.fields.slug })
  }

  return (
    <Card>
      <CardContent className={clsx(classes.card)}>
        <CardHeader
          className={clsx(classes.cardHeader)}
          action={
            state.favorites.includes(data.fields.slug) ? (
              <IconButton aria-label="settings" onClick={removeFromFav}>
                <FavoriteIcon style={{ color: theme.palette.common.black }} />
              </IconButton>
            ) : (
              <IconButton aria-label="settings" onClick={addToFav}>
                <FavoriteBorderIcon
                  style={{ color: theme.palette.common.black }}
                />
              </IconButton>
            )
          }
          title={
            <Link className={classes.titleLink} to={data.fields.slug}>
              {data.frontmatter.title}
            </Link>
          }
          titleTypographyProps={{ variant: "h3" }}
        />
        <Typography className={clsx(classes.cardText)}>
          <Hyphenated language={intl.local}>
            <TextTruncate
              line={3}
              element="span"
              truncateText="…"
              text={data.frontmatter.subtitle}
            />
          </Hyphenated>
        </Typography>
        <div className={clsx(classes.cardAction)}>
          <Button
            className={clsx(classes.buttonMain)}
            color="primary"
            variant="contained"
            href={data.frontmatter.link}
          >
            {intl.formatMessage({ id: "direktlink" })}
          </Button>
          <Button
            className={clsx(classes.buttonSecondary)}
            color="primary"
            variant="outlined"
            href={`/${data.fields.lang}${data.fields.slug}`}
          >
            {intl.formatMessage({ id: "learn-more" })}
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}

export default injectIntl(ToolCard)
